<template>
  <div id="app">
    <van-nav-bar title="申请详情" left-text="返回" left-arrow @click-left="onClickLeft" />
    <van-pull-refresh v-model="detailLoading" @refresh="onDetailRefresh">
      <div class="common-wrap">
        <van-form @submit="onSubmit" :hidden="applyLoading">
          <van-field v-if="edit" v-model="data.maintain_code" label="编号" />
          <van-field v-if="edit" v-model="data.name" label="名称" />
          <van-field v-if="edit" v-model="data.eq" label="资产" />
          <van-field v-if="edit" v-model="data.statusShow" label="状态" />
          <van-field v-if="edit" v-model="data.tagShow" label="维修/检测" />
          <van-field v-if="edit" v-model="data.stopShow" label="是否停产" />

          <van-field v-if="!edit" readonly clickable v-model="data.equipment_name" label="资产" :rules="[{ required: true, message: '请选择资产' }]" @click="showEquipmentPicker">
            <template #right-icon>
              <van-icon name="scan" @click.stop="qrSelectEq" />
            </template>
          </van-field>
          <van-field v-if="!edit && isRepair" readonly clickable v-model="data.create_admin_name" label="报修人" :rules="[{ required: true, message: '请选择报修人' }]" @click="showAdminPicker" />
          <van-field v-if="!edit" clickable v-model="data.tagShow" label="维修/检测" placeholder="点击选择类别" @click="showTag = true" right-icon="arrow-down" />
          <van-field v-if="!edit" clickable v-model="data.stopShow" label="是否停产" placeholder="点击选择停产" @click="showStop = true" right-icon="arrow-down" />

          <van-field
              autosize
              v-model="data.content"
              rows="2"
              label="详情"
              type="textarea"
              maxlength="200"
              placeholder="描述"
              show-word-limit
          />

          <van-popup v-model:show="showEquipment" position="bottom">
            <van-picker show-toolbar :columns="equipmentList" @confirm="hideEquipment" @cancel="showEquipment = false" value-key="name" :loading="loadingPicker">
              <template #title>
                <van-search v-model="equipmentQuery.name" @search="searchEquipment" placeholder="请输入搜索关键词" />
              </template>
            </van-picker>
          </van-popup>
          <van-popup v-model:show="showAdmin" position="bottom">
            <van-picker show-toolbar :columns="adminList" @confirm="hideAdmin" @cancel="showAdmin = false" value-key="name" :loading="loadingPickerAdmin">
              <template #title>
                <van-search v-model="adminQuery.name" @search="searchAdmin" placeholder="请输入搜索关键词" />
              </template>
            </van-picker>
          </van-popup>
          <van-popup v-model:show="showTag" position="bottom">
            <van-picker show-toolbar :columns="applyTag" value-key="name" @confirm="hideApply" @cancel="showTag = false" />
          </van-popup>
          <van-popup v-model:show="showStop" position="bottom">
            <van-picker show-toolbar :columns="applyStop" value-key="name" @confirm="hideStop" @cancel="showStop = false" />
          </van-popup>
          <div v-if="data.status === 3 && cmCanConfirm === 2" style="margin: 16px;" hidden>
            <van-button round block type="success" native-type="button" @click="cmWorkShopConfirm">确认</van-button>
          </div>
          <div v-if="!edit" style="margin: 16px;">
            <van-button round block type="primary" native-type="submit">提交</van-button>
          </div>
          <div v-if="edit && data.status === 0" style="margin: 16px;">
            <van-button round block type="danger" native-type="button" @click="onDelete">删除</van-button>
          </div>
        </van-form>
      </div>
    </van-pull-refresh>
  </div>
</template>
<script>
import ASSETAPI from '@/api/asset/asset'
import API from '@/api/project/apply'
import SELECT from '@/api/select'
import cmApi from '@/api/workorder/cm'
import { applyOrderStatus, applyTag, applyStop } from '@/utils/options'
import {Notify, Toast} from 'vant'
import WxApi from "@/api/wx"
import wx from "weixin-js-sdk"
import { mapGetters } from 'vuex'
export default {
  inject: ['isEdit', 'selectName', 'backUrl'],
  data() {
    return {
      edit: false,
      applyLoading: false,
      detailLoading: true,
      data: {},
      showTag: false,
      showStop: false,
      finishWx: false,
      equipmentQuery: {
        name: '',
        limit: 100
      },
      equipmentList: [],
      loadingPicker: false,
      equipmentPicker: '',
      equipmentId: '',
      showEquipment: false,
      adminQuery: {
        name: '',
        limit: 100
      },
      adminList: [],
      loadingPickerAdmin: false,
      adminPicker: '',
      adminId: '',
      showAdmin: false,
      isRepair: false,
      applyOrderStatus,
      applyTag,
      applyStop
    }
  },
  created() {
    this.getConfig()
    this.index()
  },
  computed: {
    ...mapGetters([
      'userId',
      'cmCanBegin',
      'cmCanMonitor',
      'cmCanConfirm',
      'cName',
      'jName'
    ])
  },
  methods: {
    index() {
      if (this.isEdit()) {
        this.edit = true
        this.info()
      } else {
        this.data.tagShow = '维修'
        this.data.stopShow = '是'

        this.data.tag = 1
        this.data.is_stop = 1

        const query = this.$route.query
        if (query.repair === '1') {
          this.isRepair = true
        }
      }
    },
    info() {
      this.applyLoading = true
      const postData = {
        id: this.$route.params.id
      }
      API.getOne(postData).then(res => {
        this.data = res.data.info

        //this.data.statusShow = this.selectName(this.applyOrderStatus, this.data.status)
        if (this.data.status === 0) {
          this.data.statusShow = '待维修'
        } else if ([-1, 1, 2].includes(this.data.status)) {
          this.data.statusShow = '维修中'
        } else if ([3, 4, 5].includes(this.data.status)) {
          this.data.statusShow = '维修完成'
        } else {
          this.data.statusShow = '维修完成'
        }
        this.data.tagShow = this.selectName(this.applyTag, this.data.tag)
        this.data.stopShow = this.selectName(this.applyStop, this.data.is_stop)
      }).finally(() => {
        this.applyLoading = false
      })
    },
    onSubmit() {
      this.data.type = 1
      API.create(this.data).then(res => {
        if (res.data.error_code === 1) {
          if (res.data.id && this.isRepair) {
            Toast.success('记录成功！')
            this.$router.push({
              path: `/workorder/cm_detail/${res.data.id}`,
            })
          } else {
            Toast.success('报修成功！')
            this.backUrl()
          }
        } else {
          Toast.fail(res.data.message)
          this.backUrl()
        }
      })
    },
    onDetailRefresh() {},
    onClickLeft() {
      this.$router.go(-1)
    },
    hideApply(val) {
      this.showTag = false
      this.data.tag = val.value
      this.data.tagShow = val.name
    },
    hideStop(val) {
      this.showStop = false
      this.data.is_stop = val.value
      this.data.stopShow = val.name
    },
    qrSelectEq() {
      sessionStorage.removeItem('applyEquipment')
      const self = this

      this.checkJsApi("scanQRCode").then((rel) => {
        if (rel) {
          wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
              const code = res.resultStr
              const kinds = code.split('|')
              if (kinds.length !== 2) {
                alert('此二维码格式不对，请联系管理员！！')
              }
              if (kinds[0] === 'e') {
                // 设备
                const eqCoding = kinds[1]
                ASSETAPI.eqList({ coding: eqCoding }).then(res => {
                  if (res.data.total !== 1) {
                    Toast.fail('没查询到该设备，请联系管理员！')
                  } else {
                    self.data.equipment_id = res.data.data[0]['id']
                    self.data.equipment_name = res.data.data[0]['name']
                  }
                })
              } else {
                alert('这不是设备的二维码')
              }
            }
          })
        }
      })
    },
    showEquipmentPicker() {
      this.showEquipment = true
      this.searchAction()
    },
    searchEquipment() {
      this.searchAction()
    },
    searchAction() {
      this.equipmentQuery.add_field = 'coding'
      SELECT.eqList(this.equipmentQuery).then(res => {
        this.equipmentList = res.data.data
      })
    },
    hideEquipment(val) {
      this.showEquipment = false
      this.data.equipment_id = val.id
      this.data.equipment_name = val.name
    },
    showAdminPicker() {
      this.showAdmin = true
      this.searchAdmin()
    },
    searchAdmin() {
      this.adminQuery.add_field = 'coding'
      SELECT.adminList(this.adminQuery).then(res => {
        this.adminList = res.data.data
      })
    },
    hideAdmin(val) {
      this.showAdmin = false
      this.data.create_admin_id = val.id
      this.data.create_admin_name = val.name
    },
    onDelete() {
      API.delete(this.$route.params.id).then(res => {
        if (res.data.error_code === 1) {
          Toast.success('删除成功！')
        }
        this.$router.go(-1)
      })
    },
    cmWorkShopConfirm() {
      const postData = {
        id: this.$route.params.id
      }
      cmApi.cmWorkShopConfirm(postData).then(res => {
        if (res.data.error_code === 1) {
          Notify({
            type: 'success',
            message: '恭喜你，提交成功'
          })
          this.$router.go(-1)
        } else {
          this.$message.error(res.data.message)
          Notify({
            type: 'danger',
            message: res.data.message
          })
        }
      })
    },

    // wechat init
    getConfig() {
      const self = this
      let url = /(Android)/i.test(navigator.userAgent) ? location.href.split('#')[0] : window.localStorage.getItem('scanUrl');

      WxApi.wxJSTicket({ url: url }).then((res) => {
        const data = res.data;
        wx.config({
          beta: true,
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: data.appId, // 必填，公众号的唯一标识
          timestamp: data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.nonceStr, // 必填，生成签名的随机串
          signature: data.signature, // 必填，签名
          jsApiList: ['chooseImage','uploadImage','getLocalImgData','scanQRCode','checkJsApi'] // 必填，需要使用的JS接口列表
        })
        wx.ready(function () {
          console.log("成功")
          self.finishWx = true
        })
        wx.error(function (err) {
          console.log(err)
        })
      })
    },
    checkJsApi(api) {
      //检测api是否可用
      return new Promise((rel, err) => {
        wx.checkJsApi({
          jsApiList: [api], // 需要检测的JS接口列表，所有JS接口列表见附录2,
          success: function (res) {
            // 以键值对的形式返回，可用的api值true，不可用为false
            // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
            if (res.checkResult && res.checkResult.scanQRCode == false) {
              this.$toast("微信版本较低，请升级后重试！")
              Toast.fail('微信版本较低，请升级后重试！')
              err("版本低")
            } else {
              rel(true)
            }
          }
        })
      })
    },
  }
}
</script>
